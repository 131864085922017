import PropTypes from 'prop-types';

import capitalize from 'lodash/fp/capitalize';

import { legacyTheme, styled } from '@prose-ui/legacy';
import Image from 'next/image';

import Typography from 'Components/Typography';

import editIconCheckoutOptimisation from 'assets/images/icon_edit_checkout_optimisation.svg';

const Root = styled.section`
  display: grid;
  grid-template-areas:
    'title   edit'
    'content content';
  grid-template-columns: auto min-content;
  grid-row-gap: ${legacyTheme.spacing.s16};
  grid-column-gap: ${legacyTheme.spacing.s16};
  width: 100%;
  padding: 22px 0;

  border-bottom: ${props => (props.border ? '1px solid rgba(40, 51, 51, 0.2)' : 'none')};

  &:last-of-type {
    border-bottom: 0;
  }

  ${legacyTheme.breakpoints.up('md')} {
    gap: 48px;
    grid-template-areas: 'title content edit';
    grid-template-columns: 127px auto min-content;
  }
`;

const TitleContainer = styled.div`
  grid-area: title;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: content;
`;

const EditButtonContainer = styled.div`
  flex-shrink: 0;
  grid-area: edit;
`;

const EditButton = styled.button`
  background-color: transparent;
  appearance: none;
  border: none;
  white-space: nowrap;

  color: ${legacyTheme.palette.common.grey.dark};
  cursor: pointer;
  font-size: 11px;
`;

const CheckoutBlock = ({ border, children, dataTestId, id, onEdit, title }) => {
  return (
    <Root border={border} data-testid={dataTestId} id={id}>
      <TitleContainer>
        <Typography align="left" variant="h3">
          {capitalize(title)}
        </Typography>
      </TitleContainer>

      <Content>{children}</Content>

      {onEdit && (
        <EditButtonContainer>
          <EditButton data-testid={`${dataTestId}-edit-link`} onClick={onEdit} type="button">
            <Image
              alt={`${title} section edit button icon`}
              height={14}
              src={editIconCheckoutOptimisation}
              width={14}
            />
          </EditButton>
        </EditButtonContainer>
      )}
    </Root>
  );
};

CheckoutBlock.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
  id: PropTypes.string,
  onEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  title: PropTypes.string.isRequired,
};

CheckoutBlock.defaultProps = {
  border: true,
  dataTestId: null,
  id: null,
  onEdit: null,
};

export default CheckoutBlock;
